.loginpage {
    justify-content: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../assiets/loginbg.webp') no-repeat;
    // background-color: greenyellow;
    background-size: cover;
    background-position: center;
}

.login {
    width: 520px;
    height: 300px;
    // background: rgba(0, 128, 0, 0.164);
    border: 2px solid rgba($color: #fff, $alpha: 0.2);
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
    backdrop-filter: blur(30px);
    color: #fff;
    border-radius: 10px;
    padding: 30xp 40px;

    h1 {
        font-size: 36px;
        text-align: center;
    }

    .input-box {
        position: relative;
        width: 100%;
        height: 50px;
        margin: 30px 0;
    }

    input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: 2px solid rgba($color: #fff, $alpha: 0.2);
        outline: none;
        border-radius: 40px;
        font-size: 16px;
        color: #fff;
        padding: 20px 45px 20px 20px;
    }

    input::placeholder {
        color: #fff;
    }

    .icon {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
    }

    .remember-forgot {
        display: flex;
        justify-content: space-between;
        font-size: 14.5px;
        margin: -15px 0 15px;

        label input {
            accent-color: #fff;
            margin-right: 4px;
        }

        a {
            color: #fff;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
        }
    }

    button {
        width: 100%;
        height: 45px;
        background-color: #fff;
        border: 0;
        outline: 0;
        cursor: pointer;
        border-radius: 40px;
        font-size: 16px;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.1);
        color: #333;
        font-weight: 700;
    }
}