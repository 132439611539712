#popUPparent {
    background-color: rgba(0, 0, 0, 0.432);
    display: flex;
    justify-content: center;
    align-items: center;

    #popUP {
        width: 40%;
        height: 30%;
    }
}