@import './navbar.scss';
@import './login.scss';
@import './loading.scss';
@import './popup.scss';
@import './statistiques.scss';
@import './logout.scss';
@import './spinner.scss';

* {
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: 'Lato', sans-serif;
    line-height: 1;
}

body {
    background-color: #f6f7f9;
    overflow: hidden;
    text-decoration: none;
}

a {
    text-decoration: none;
}