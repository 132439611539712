a {
    text-decoration: none;
}

#navbar {
    p {
        color: greenyellow;
    }

    ul {
        .nav-active {
            opacity: 0.9;
            font-size: 19px;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: 30%;
                height: 4px;
                background: greenyellow;
                width: 0;
                left: 50%;
                border-radius: 50px;
                animation: nav-animation 400ms forwards;
            }

            @keyframes nav-animation {
                to {
                    transform: translateX(-50%);
                    width: 100%;
                }
            }
        }
    }
}